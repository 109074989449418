import '@node_modules/es6-shim/es6-shim.js';
import "@node_modules/es6-promise-polyfill/promise.min.js";

import $ from "@node_modules/jquery/dist/jquery.js";
import * as popperjs from "@node_modules/@popperjs/core/dist/esm/popper.js";
import bootstrap from "@node_modules/bootstrap/dist/js/bootstrap.min.js";
import moment from "@node_modules/moment/min/moment-with-locales.min.js";
import wnumb from "@node_modules/wnumb/wNumb.js";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.$ = window.jQuery = $;
window.bootstrap = bootstrap;
window.Popper = popperjs;
window.moment = moment;
window.wNumb = wnumb;

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: import.meta.env.VITE_BROADCAST_DRIVER,
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    wsHost: window.location.hostname,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: false,
});
